import React from "react";
import * as styles from "./notFoundPage.module.css";
import Button from "./button";
import TextArea from "./textArea";

export default class NotFoundPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    refreshPage = () => {
        window.location.reload();
    };

    render() {
        return (
            <div className={styles.container}>
                <TextArea center={true}>
                    <h1>Oh No! &#x1F631;</h1>
                    <p>
                        I'm afraid we couldn't find (or get) the requested {this.props.notFoundName || "item"}
                        <br />
                        maybe try again?
                    </p>
                    <br />
                    <div className={styles.flex_row}>
                        <Button displayMode={1} hoverMode={6} text={"Go Home"} style={{ margin: 0, marginRight: "5px" }} to={`/`} />
                        <Button displayMode={1} hoverMode={6} text={"Try Again"} style={{ margin: 0, marginLeft: "5px" }} onClick={this.refreshPage} />
                    </div>
                </TextArea>
            </div>
        );
    }
}
