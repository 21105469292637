import React from "react";
import * as styles from "./navPanel.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

export default class NavPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    onTabClick = (tab) => {
        if (tab.disabled !== true) {
            tab.onClick();
        }
    };

    render() {
        return (
            <div className={`${styles.container} ${this.props.darkMode === true ? styles.dark_mode : ""} ${this.props.extraBottomMargin === true ? styles.extra_bottom_margin : ""}`}>
                {this.props.showScrollTop && (
                    <span onClick={this.scrollToTop}>
                        <FontAwesomeIcon icon={faArrowUp} />
                    </span>
                )}
                {Array.isArray(this.props.tabs) &&
                    this.props.tabs.map((tab, index) => (
                        <span
                            key={`navpanel_tab_${index}`}
                            className={`${tab.disabled === true ? styles.disabled : ""} ${tab.isAction === true ? styles.action : ""}`}
                            onClick={() => {
                                this.onTabClick(tab);
                            }}
                        >
                            {tab.text}
                        </span>
                    ))}
            </div>
        );
    }
}
