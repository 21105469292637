import React from "react";
import { Link } from "gatsby";
import * as styles from "./saveBar.module.css";
import Button from "./button";

export default class SaveBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    onSave = () => {
        if (this.props.disabled !== true && this.props.onSave) {
            this.props.onSave();
        }
    };

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.inner}>
                    {!isNaN(this.props.percentageComplete) && this.props.percentageComplete > 0 && (
                        <div className={styles.percentageBar}>
                            <div className={styles.bar} style={{ width: `${Math.min(100, Math.max(0, this.props.percentageComplete))}%` }} />
                        </div>
                    )}
                    <Button displayMode={9} hoverMode={6} large={true} disabled={this.props.disabled === true} text={this.props.text || "Save Changes"} onClick={this.onSave} style={{ margin: 0 }} />
                </div>
            </div>
        );
    }
}
