import React from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { withAuthentication } from "../../../hoc/withAuthentication";
import * as styles from "./index.module.css";
import ScreenLoad from "../../../components/screenLoad";
import Main from "../../../components/main";
import NotFoundPage from "../../../components/notFoundPage";
import SocialInput from "../../../components/socialInput";
import Button from "../../../components/button";
import Input from "../../../components/input";
import NavPanel from "../../../components/navPanel";
import DeleteTag from "../../../components/deleteTag";
import ContentArea from "../../../components/contentArea";
import MediaStickyPreview from "../../../components/mediaStickyPreview";
import TextArea from "../../../components/textArea";
import Checkbox from "../../../components/checkbox";
import SaveBar from "../../../components/saveBar";
import Consts from "../../../config/consts";
import Common from "../../../config/common";
import Provider from "../../../config/provider";
import Util from "../../../config/util";
import Api from "../../../config/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faSpinner, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faInstagram, faFacebookF, faTiktok, faSnapchatGhost, faLinkedinIn, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED],
        redirectPage: "/",
    },
    class CollectionEdit extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                initialising: true,
                saving: false,
                requiresSigning: props.auth.user.requiresSigning === true,
                collectionId: props.params.collection_id,
                collection: null,
                hasUpdatedName: false,
                hasUpdatedDescription: false,
                hasUpdatedDisplayImage: false,
                hasUpdatedSocials: false,

                displayImageKey: null,
                uploadedDisplayImageKey: null,
                dataDisplayImage: null,
                base64DisplayImage: null,

                name: "",
                description: "",
                socialLinks: [],

                hasConfirmedDelete: false,
                deleting: false,
            };
        }

        componentDidMount() {
            Api.market({
                endpoint: "/content/collection",
                method: "GET",
                data: {
                    collectionId: this.props.params.collection_id,
                },
            })
                .then((res) => {
                    if (!this.props.auth.user.profiles.some((p) => p.displayName === Common.objectPropertyExists(res, "data.creator.displayName", ""))) {
                        navigate(`/c/${this.state.collectionId}`);
                    } else {
                        this.setState({
                            initialising: false,
                            collection: res.data,
                            name: res.data.name,
                            description: res.data.description.en,
                            displayImageKey: res.data.displayImage,
                            socialLinks: res.data.socialLinks,
                        });
                    }
                })
                .catch((e) => {
                    this.setState({
                        initialising: false,
                        collection: null,
                    });
                });
        }

        onSelectDisplayImageFile = () => {
            this.selectDisplayImageFile()
                .then((file) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = () => {
                        console.log(reader);
                        this.setState({
                            hasUpdatedDisplayImage: true,
                            uploadedDisplayImageKey: null,
                            dataDisplayImage: file,
                            base64DisplayImage: reader.result,
                        });
                    };
                })
                .catch((e) => {
                    Util.notify.error("Invalid file: " + e);
                });
        };
        selectDisplayImageFile = () => {
            return new Promise((resolve, reject) => {
                let input = document.createElement("input");
                input.type = "file";
                input.multiple = false;
                input.accept = "image/*";
                input.onchange = (_) => {
                    let files = Array.from(input.files);
                    if (files[0].size > 50000000) {
                        reject("File size too big.");
                    } else {
                        resolve(files[0]);
                    }
                };
                input.click();
            });
        };

        // Finds a particular social object from the currently authed user
        findSocialUrl = (collection, type) => {
            if (!Array.isArray(collection.socialLinks)) {
                return null;
            } else {
                let link = collection.socialLinks.filter((i) => {
                    return i.type === type;
                });
                if (link.length <= 0) {
                    return null;
                } else {
                    return link[0].url;
                }
            }
        };

        onUnlockAccount = () => {
            Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                .then((signature) => {
                    Api.market({
                        endpoint: "/auth/message",
                        method: "POST",
                        data: {
                            signature: signature,
                        },
                    })
                        .then(() => {
                            // Successfully verified the signature - a refresh
                            // should sort out any currently hidden data
                            window.location.reload();
                        })
                        .catch((e) => {
                            console.error(e);
                        });
                })
                .catch((e) => {
                    console.error(e);
                });
        };

        onNameUpdated = (text) => {
            this.setState({
                name: text,
                hasUpdatedName: true,
            });
        };
        onDescriptionUpdated = (text) => {
            this.setState({
                description: text,
                hasUpdatedDescription: true,
            });
        };
        onSocialsChanged = (socials) => {
            this.setState({
                hasUpdatedSocials: true,
                socialLinks: socials,
            });
        };

        onTagsUpdated = (text) => {
            let tag_split = text
                .replace(/#/g, " ")
                .split(" ")
                .filter((i) => {
                    return i.trim().length > 0;
                });
            this.setState({
                tags: tag_split,
                hasUpdatedDescription: true,
            });
        };

        uploadDisplayImage = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.dataDisplayImage) {
                    reject();
                } else if (this.state.uploadedDisplayImageKey) {
                    resolve(this.state.uploadedDisplayImageKey);
                } else {
                    Api.market({
                        endpoint: "/content/upload/url/",
                        method: "GET",
                        data: {
                            contentType: this.state.dataDisplayImage.type.toLowerCase(),
                            dataType: "COLLECTION_DISPLAY_IMAGE",
                        },
                    })
                        .then((upload_res) => {
                            axios({
                                method: "PUT",
                                url: upload_res.url,
                                headers: {
                                    "Content-Type": this.state.dataDisplayImage.type.toLowerCase(),
                                },
                                data: this.state.dataDisplayImage,
                            })
                                .then(() => {
                                    this.setState(
                                        {
                                            uploadedDisplayImageKey: upload_res.key,
                                        },
                                        () => {
                                            this.checkUploadStatus(upload_res.key, 20)
                                                .then(() => {
                                                    resolve(upload_res.key);
                                                })
                                                .catch((e) => {
                                                    reject();
                                                });
                                        }
                                    );
                                })
                                .catch(reject);
                        })
                        .catch(reject);
                }
            });
        };

        checkUploadStatus = (key, maxRetries, retryCount = 0) => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/content/upload/status/",
                    method: "GET",
                    data: {
                        key,
                    },
                })
                    .then((status_res) => {
                        if (status_res.status !== "UPLOADED") {
                            if (maxRetries > retryCount) {
                                setTimeout(() => {
                                    this.checkUploadStatus(key, maxRetries, retryCount + 1)
                                        .then(resolve)
                                        .catch(reject);
                                }, 1000);
                            } else {
                                resolve(false);
                            }
                        } else {
                            resolve(true);
                        }
                    })
                    .catch(reject);
            });
        };

        onSave = () => {
            let request_data = {};

            // Run through any updated details and create an object worth of
            // data we want to update
            if (this.state.hasUpdatedName) {
                request_data.name = this.state.name;
            }
            if (this.state.hasUpdatedDescription) {
                request_data.description = this.state.description;
            }
            if (this.state.hasUpdatedSocials) {
                request_data.socialLinks = this.state.socialLinks;
            }

            // Check we have something to update
            if (Object.keys(request_data).length <= 0 && !this.state.hasUpdatedDisplayImage) {
                // Nothing to update - just redirect
                navigate(`/c/${this.state.collectionId}`);
            } else {
                // Set the save flag
                this.setState(
                    {
                        saving: true,
                    },
                    () => {
                        const onPatch = () => {
                            Api.market({
                                endpoint: "/content/collection",
                                method: "PATCH",
                                data: {
                                    collectionId: this.state.collectionId,
                                    ...request_data,
                                },
                            })
                                .then(() => {
                                    // Successfully saved user
                                    navigate(`/c/${this.state.collectionId}`);
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            saving: false,
                                        },
                                        () => {
                                            Util.notify.error(`Failed to update your collection. Please check the data and retry or try again later.`);
                                        }
                                    );
                                });
                        };

                        if (this.state.hasUpdatedDisplayImage) {
                            this.uploadDisplayImage()
                                .then((display_image_key) => {
                                    request_data.displayImageKey = display_image_key;
                                    onPatch();
                                })
                                .catch((e) => {
                                    Util.notify.error(`Failed to upload the collection image. Please retry or try again later.`);
                                    this.setState({
                                        saving: false,
                                    });
                                });
                        } else {
                            onPatch();
                        }
                    }
                );
            }
        };

        refreshPage = () => {
            window.location.reload();
        };

        onConfirmDelete = (id, val) => {
            this.setState({
                hasConfirmedDelete: val,
            });
        };
        onDeleteCollection = () => {
            if (!this.state.hasConfirmedDelete) {
                Util.notify.error(`Please confirm you want to delete this collection by tapping the Delete Confirmation checkbox.`);
            } else {
                this.setState(
                    {
                        deleting: true,
                    },
                    () => {
                        Provider.SignMessageWithAccountWallet(this.props.auth.user.addresses)
                            .then((signature) => {
                                Api.market({
                                    endpoint: "/content/collection",
                                    method: "DELETE",
                                    data: {
                                        collectionId: this.state.collectionId,
                                        signature: signature,
                                    },
                                })
                                    .then(() => {
                                        const bminted_profile = this.props.auth.user.profiles.filter((p) => p.scheme === "BMINTED");
                                        if (bminted_profile.length > 0 && bminted_profile[0].displayName) {
                                            navigate(`/u/${bminted_profile[0].displayName}`);
                                        } else {
                                            navigate("/");
                                        }
                                    })
                                    .catch((e) => {
                                        this.setState(
                                            {
                                                deleting: false,
                                            },
                                            () => {
                                                Util.notify.error(`Failed to delete your collection. Please retry or try again later.`);
                                            }
                                        );
                                    });
                            })
                            .catch((e) => {
                                this.setState(
                                    {
                                        deleting: false,
                                    },
                                    () => {
                                        Util.notify.error(`Failed to delete your collection. Please retry or try again later.`);
                                    }
                                );
                            });
                    }
                );
            }
        };

        render() {
            return !this.state.collection ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Collection"} />
                </Main>
            ) : (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    noFooterMargin={true}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea extraTopPadding={true} centered={true} slim={true}>
                        <ContentArea header={"Collection Image."} bottomRule={false}>
                            <TextArea>
                                <div className={styles.profile_image_container}>
                                    <div className={styles.profile_image_left}>
                                        <div className={styles.profile_image}>
                                            {!!this.state.base64DisplayImage ? (
                                                <img src={this.state.base64DisplayImage} />
                                            ) : (
                                                this.state.displayImageKey && <img src={`${process.env.GATSBY_STORAGE}collection/${this.state.displayImageKey}`} />
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles.profile_image_right}>
                                        <TextArea>
                                            <p>This image should be 500x500 in resolution and can be of filetype: .png, .gif or .jpg</p>
                                            <br />
                                            <Button displayMode={1} hoverMode={6} text={"Select Image"} style={{ margin: 0 }} onClick={this.onSelectDisplayImageFile} />
                                        </TextArea>
                                    </div>
                                </div>
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Collection Details."} bottomRule={true}>
                            <TextArea header={"Name."}>
                                <p>Give your Collection a name that is unique amongst your own Collections. This name will be publicly visible.</p>
                                <Input placeholder={"Name your collection"} onTextChanged={this.onNameUpdated} defaultValue={this.state.name} maxLength={50} />
                            </TextArea>
                            <br />
                            <TextArea header={"Description."}>
                                <p>Write out a paragraph or two describing what your collection is of. This description is publicly visible.</p>
                                <Input multiline={true} placeholder={"Describe your collection"} onTextChanged={this.onDescriptionUpdated} defaultValue={this.state.description} maxLength={400} />
                            </TextArea>
                            <br />
                            <TextArea header={"Socials (Public)."}>
                                <p>Your social links will appear on your collections page.</p>
                                <SocialInput socials={this.state.socialLinks} allowMultipleCustom={true} socialsChanged={this.onSocialsChanged} />
                            </TextArea>
                            <br />
                        </ContentArea>

                        <ContentArea header={"Delete."} slim={true} bottomRule={false}>
                            <TextArea>
                                <p>
                                    You can choose to delete your collection if you no longer want it around, this won't affect the associated media. Please note though that this will be permenant and
                                    cannot be undone. Tap the checkbox below to confirm you understand and allow you to delete.
                                </p>
                                <Checkbox checked={this.state.hasConfirmedDelete} onCheckChanged={this.onConfirmDelete} text={"I understand and request you delete this collection."} />
                                <Button
                                    displayMode={5}
                                    hoverMode={6}
                                    disabled={!this.state.hasConfirmedDelete}
                                    text={this.state.deleting ? "Deleting..." : "Delete this Collection"}
                                    onClick={this.onDeleteCollection}
                                    style={{ margin: 0, marginTop: 15 }}
                                />
                            </TextArea>
                            <br />
                        </ContentArea>
                        <br />
                        <br />
                    </ContentArea>

                    <MediaStickyPreview
                        media={{
                            ...this.state.collection,
                            name: this.state.name,
                            description: this.state.description,
                        }}
                    />

                    <NavPanel showScrollTop={true} extraBottomMargin={true} />

                    <SaveBar
                        disabled={this.state.saving || (!this.state.hasUpdatedProfileImage && !this.state.hasUpdatedName && !this.state.hasUpdatedDescription && !this.state.hasUpdatedSocials)}
                        text={this.state.saving ? "Saving..." : "Save Changes"}
                        onSave={this.onSave}
                    />
                </Main>
            );
        }
    }
);
