import React from "react";
import { Link } from "gatsby";
import * as styles from "./deleteTag.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";

export default class DeleteTag extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <span className={styles.container}>
                <span className={styles.delete_button} onClick={this.props.onDelete}>
                    <FontAwesomeIcon icon={this.props.deleting ? faSpinner : faTimes} className={`${this.props.deleting ? "animate_rotate" : ""}`} />
                </span>
                {this.props.image && (
                    <span className={styles.image_container}>
                        <img src={this.props.image} />
                    </span>
                )}
                <span className={styles.text}>{this.props.text}</span>
            </span>
        );
    }
}
